<template>
    <form @submit.prevent="deleteRecord">
        <p class="mb-6 text-base max-w-xl">{{ attributes.message }}</p>

      <BaseBtn type="button" @click="$parent.close()" text="Cancel" class="mr-4 text-black bg-white" :outlined="true" />
      <BaseBtn type="submit" text="Delete"  class="text-white bg-gray-800 hover:bg-gray-500"/>
      <FlashMessage :message="message" :error="error" />
    </form>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

import { mapGetters } from "vuex";
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn";
import BaseInput from "@/components/BaseInput";
import DeleteService from "@/services/DeleteService";
import FlashMessage from "@/components/FlashMessage";

export default {
  name: "AuthUserForm",
  components: {
    BaseBtn,
    BaseInput,
    FlashMessage,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      error: null,
      message: null,
    };
  },
  computed: {
    ...mapGetters("school", ["delete"]),
  },
  methods: {
    deleteRecord() {
      this.error = null;
      this.message = null;

        DeleteService.delete(this.attributes.id, this.attributes.resource)
          .then(() => {
            this.$parent.close();
            this.$eventBus.$emit(this.attributes.resource+":deleted", true);
            this.$eventBus.$emit("record:deleted", true);
          })
          .catch((error) => (this.error = getError(error)));
      
    },
  },
  mounted() {
  },
};
</script>
